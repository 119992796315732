<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <v-row class="ma-0 pa-0">
        <v-col
          class="pa-0"
          cols="6"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="today"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="today"
                label="납품일자"
                autocomplete="off"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="today"
              locale="ko-ko"
              @input="onDateInput"
            />
          </v-dialog>
        </v-col>
        <v-col
          cols="6"
          class="mb-2"
          align="right"
        >
          <v-btn
            depressed
            color="primary"
            @click="onClickInputEnd"
          >
            직접 입고
          </v-btn>
        </v-col>
      </v-row>
      <dx-data-grid
        ref="refDeliveryGrid"
        :data-source="deliveryDetailListInfo"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        :height="gridHeight"
        :focused-row-enabled="true"
        key-expr="seqId"
        :focused-row-key.sync="focusedRowKeyOrder"
        @focused-row-changed="onSelectionChangedDetail"
      >
        <DxSearchPanel
          :visible="false"
          :width="120"
          placeholder="검색..."
        />
        <DxColumn
          :allow-editing="true"
          :fixed="false"
          caption="거래처명"
          data-field="거래처명"
          :min-width="100"
        />
        <DxColumn
          :allow-editing="true"
          :fixed="false"
          caption="품번"
          data-field="품번"
          :min-width="100"
        />
        <DxColumn
          :allow-editing="true"
          caption="수량"
          data-field="납품수량"
          data-type="number"
          :visible="true"
          format="#,##0"
          :min-width="60"
        />
        <DxColumn
          :allow-editing="true"
          caption="실수량"
          data-field="입고수량"
          data-type="number"
          :visible="true"
          format="#,##0"
          :min-width="60"
        />
        <DxSummary>
          <DxTotalItem
            value-format="#,##0"
            column="거래처명"
            display-format="{0} (건)"
          />
        </DxSummary>
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtuala"
          show-scrollbar="always"
          :use-native="false"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
    </v-container>
    <div
      class="left-draw-wrap"
    >
      <v-app-bar
        id="left-draw-app-bar"
        dark
        height="50px"
      >
        <v-icon
          large
          @click="onClickBefore"
        >
          mdi-chevron-left
        </v-icon>
        <v-toolbar-title style="margin-left:10px;">
          {{ title }}
        </v-toolbar-title>
      </v-app-bar>
      <div
        class="left-draw-content"
      >
        <v-container>
          <div
            v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
            class="qrcode-wrap"
          >
            <QrCodeScanComponent @scan-result="onPCodeScan" />
          </div>
          <v-row class="pb-1">
            <v-col
              class="pb-0 pt-0"
              :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
              :cols="$store.getters.getMobileModel !== 'PDA' ? 7 : 8"
            >
              <v-autocomplete
                id="input-pcode"
                ref="refPcode"
                v-model="selectedPCode"
                :items="purchaseInfoList"
                label="품번"
                :item-text="getText"
                item-value="품번"
                auto-select-first
                @change="onPCodeChange"
                @keyup.enter.prevent="onEnterPcode"
              />
            </v-col>
            <v-col
              v-if="$store.getters.getMobileModel !== 'PDA'"
              class="pb-0 pt-4 pl-0"
              cols="1"
            >
              <v-icon
                @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
              >
                mdi-camera
              </v-icon>
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="4"
            >
              <v-text-field
                id="input-qty"
                v-model="inputQty"
                label="입고수량"
                autocomplete="off"
                type="number"
                clearable
                @keyup.enter.prevent="onEnterQty"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-autocomplete
                id="input-companyCode"
                ref="refCompanyCode"
                v-model="입고업체"
                :items="$store.getters.getPartnersInfo"
                label="입고업체"
                :item-text="getVendorName"
                item-value="거래처코드"
                auto-select-first
              />
            </v-col>
            <v-col
              class="pb-0 pt-0"
              cols="6"
            >
              <v-text-field
                id="input-lotNo"
                v-model="inputLotNo"
                autocomplete="off"
                label="롯트번호"
                type="number"
                @keyup.enter.prevent="onEnterLotNo"
              />
            </v-col>
          </v-row>
          <v-row v-if="사급여부">
            <v-col
              class="pb-0 pt-0"
              cols="12"
            >
              <v-autocomplete
                id="input-vendorCode"
                ref="refVendorCode"
                v-model="사급소재협력사코드"
                :items="$store.getters.getPartnersInfo"
                label="외주업체"
                :item-text="getVendorName"
                item-value="거래처코드"
                auto-select-first
              />
            </v-col>
          </v-row>
          <v-row
            class="mt-0 mb-0 pt-1"
          >
            <v-col
              cols="12"
            >
              <v-btn
                width="100%"
                color="primary"
                @click="onEnterLotNo"
              >
                {{ 등록구분명 }}
              </v-btn>
            </v-col>
          </v-row>
          <v-text-field
            v-model="selectedGoodsName"
            label="품명"
            autocomplete="off"
            :readonly="true"
            filled
          />
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                :value="unitBom"
                label="BOM단위"
                autocomplete="off"
                :readonly="true"
                filled
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                :value="unitText"
                label="주문단위"
                :readonly="true"
                autocomplete="off"
                filled
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="unitRate"
                label="변환율"
                :readonly="true"
                autocomplete="off"
                filled
              />
            </v-col>
          </v-row>
          <div style="margin-top:10px; background-color:white;">
            <dx-data-grid
              ref="refGoodsInputGrid"
              :data-source="goodsInputListInfo"
              :show-borders="true"
              :show-row-lines="false"
              :show-column-lines="true"
              :hover-state-enabled="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :allow-column-reordering="true"
              :row-alternation-enabled="true"
              :height="gridHeightSub"
              :focused-row-enabled="true"
              key-expr="발주번호"
              :focused-row-key.sync="focusedRowKey"
              @row-removed="onRowRemoved"
            >
              <DxEditing
                :allow-deleting="true"
                :allow-updating="false"
                :allow-adding="false"
                :use-icons="true"
                mode="row"
              />
              <DxColumn
                :allow-editing="false"
                caption="일련번호"
                data-field="seqId"
                :visible="false"
              />
              <DxColumn
                :allow-editing="false"
                caption="품번"
                data-field="품번"
              />
              <DxColumn
                :allow-editing="false"
                caption="입고수량"
                data-field="수량"
                format="#,##0"
              />
              <DxColumn
                width="50"
                :buttons="editButtons"
                type="buttons"
              />
              <DxSummary>
                <DxTotalItem
                  value-format="#,##0"
                  show-in-column="품번"
                  display-format="{0} (건)"
                />
              </DxSummary>
              <dx-paging :enabled="false" />
              <dx-scrolling
                mode="virtual"
              />
              <dx-sorting mode="multiple" />
            </dx-data-grid>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import DxDataGrid, { DxScrolling, DxColumn, DxEditing, DxPaging, DxSorting, DxSearchPanel, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import axios from 'axios'
// import HttpService from '../../share/service/HttpService'
export default {
  name: 'EmptView',
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxSummary,
    DxTotalItem,
    DxEditing,
    QrCodeScanComponent
  },
  props: {
    goodsType: {
      type: String,
      default: 'others'
    },
    title: {
      type: String,
      default: '외주품입고 현황'
    }
  },
  data: function () {
    return {
      isSaving: false,
      userInfo: null,
      deliveryDetailListInfo: null,
      gridHeight: null,
      today: AppLib.getToday(),
      datePickerWidth: '350px',
      focusedRowKeyOrder: null,
      selectedOrder: null,
      htmlWdth: null,
      modal: false,
      입고업체: null,
      사급소재협력사코드: null,
      // slide view
      showQrScaner: 'none',
      purchaseInfoList: [],
      selectedPCode: null,
      inputQty: null,
      inputLotNo: null,
      selectedGoodsName: null,
      unitText: null,
      unitBom: null,
      unitRate: null,
      goodsInputListInfo: [],
      gridHeightSub: null,
      focusedRowKey: null,
      editButtons: ['delete'],
      등록구분명: '입고 등록',
      사급여부: false
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.unitLookup = this.$store.getters.getBaseDataByType(ConstDef.단위)
    this.refreshDeliveryList()
    this.refreshPurchaseInfo()
    this.refreshInputGoodsList()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.gridHeight = (this.$Q('html').height() - 145)
    this.handleResize()
  },
  updated () {
  },
  methods: {
    getText (row) {
      let 차종 = ''
      if (row.차종 !== null && row.차종 !== undefined) {
        차종 = `(${row.차종})`
      }
      return `${row.품번}${차종}`
    },
    getVendorName (row) {
      return `${row.거래처명}`
    },
    handleResize () {
      this.htmlWdth = this.$Q('html').width()
      if (this.$Q('.left-draw-wrap').css('left') !== '0px') {
        this.$Q('.left-draw-wrap').css('left', `${this.htmlWdth}px`)
      }
      this.$Q('.left-draw-wrap').css('height', `${this.$Q('html').height()}px`)

      this.gridHeightSub = (this.$Q('html').height() - 370)
    },
    onClickInputEnd () {
      this.selectedOrder = null
      this.showSlide(false)
    },
    onDateInput (e) {
      this.$refs.dialog.save(this.today)
      this.refreshDeliveryList()
    },
    onSelectionChangedDetail (e) {
      if (e != null && e.row === undefined) {
        return
      }
      this.selectedOrder = e.row.data
      console.log(this.selectedOrder)
      this.selectedPCode = e.row.data.품번
      this.inputQty = e.row.data.납품수량
      this.입고업체 = e.row.data.거래처코드
      this.showSlide(true)
    },
    showSlide (isSelectedRow) {
      const vm = this
      this.$Q('.left-draw-wrap').animate({
        left: '0'
      }, 800, function () {
        vm.$Q('#left-draw-app-bar').css('position', 'fixed')
        vm.$Q('.left-draw-content').css('padding-top', '50px')
        vm.$Q('#input-pcode').focus()
        if (isSelectedRow) {
          vm.onPCodeChange(vm.selectedOrder.품번)
        }
        vm.focusedRowKeyOrder = null
      })
    },
    refreshDeliveryList () {
      const param = {
        납품일자: this.today,
        자재유형: this.goodsType
      }
      this.$_sp.runNoEncodeFindProc('spFindAllB2bDeliveryListByDate', param)
        .then((data) => {
          this.deliveryDetailListInfo = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // ********************** 슬라이드 뷰 ********************
    async addInventory () {
      if (this.isSaving) return
      let seqId = null
      let 입고여부 = true
      if (this.selectedOrder !== null) {
        await this.$_sp.runNoEncodeFindSqlProc('창고수불', { 발주번호: this.selectedOrder.seqId })
          .then((data) => {
            if (this.$_sp.MakeModel(data).length > 0) {
              입고여부 = false
            }
            seqId = this.selectedOrder.seqId
            this.deleveryDateTime = this.selectedOrder.납품일자
          })
      } else {
        this.deleveryDateTime = AppLib.getDeliveryTime(this.$store.getters.getSysParamByKey('재고관리기준시간'))
        seqId = AppLib.uuidSequential()
      }
      if (입고여부 === false) {
        this.$snotify.error('이미 입고처리 되었습니다.')
        return
      }
      let 단가 = 0
      await this.$_sp.runNoEncodeFindProc('spFindAllCostOrSaleByGoodsCodeAndSaleType', { 입출고구분: 1, 품번: this.selectedPCode, 기준일: AppLib.getNow() })
        .then((data) => {
          const 가격정보 = this.$_sp.MakeModel(data)
          if (가격정보.length > 0) {
            단가 = 가격정보[0].가격
          } else {
            this.$snotify.info('가격정보가 등록되어있지 않습니다. 추후 수정하여주세요.')
          }
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      // 더블클릭방지(이중클릭)
      this.isSaving = true
      const inTime = AppLib.getDeliveryTime(this.$store.getters.getSysParamByKey('재고관리기준시간'))
      const param = [
        {
          발생일자: inTime,
          입출고구분: this.$_.findWhere(ConstDef.PURCHASE_INOUT_CODE, { 코드명: '자재입고' }).코드,
          품번: this.selectedPCode,
          수량: Math.round(parseInt(this.inputQty) * this.unitRate),
          단가: 단가,
          입고업체: this.입고업체,
          발주번호: seqId,
          마감일자: this.deleveryDateTime,
          lotNo: this.inputLotNo,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName,
          createtime: AppLib.getNow()
        }
      ]
      if (this.사급여부 === true) {
        param.push(
          {
            발생일자: inTime,
            입출고구분: ConstDef.사급출고,
            품번: this.selectedPCode,
            수량: Math.round(parseInt(this.inputQty) * this.unitRate),
            단가: 단가,
            입고업체: this.사급소재협력사코드,
            발주번호: seqId,
            마감일자: this.deleveryDateTime,
            lotNo: this.inputLotNo,
            createid: this.userInfo.userName,
            updateid: this.userInfo.userName,
            createtime: AppLib.getNow()
          })
      }
      const 창고수불 = this.$_sp.runInsertSqlProc('창고수불', null, param)
      const 협력사거래내역서 = this.$_sp.runUpdateSqlProc('협력사거래내역서', ['seqId'], null, [{ seqId: seqId, 납품확인: true, 실납품수량: Math.round(parseInt(this.inputQty) * this.unitRate) }])
      await axios.all([창고수불, 협력사거래내역서])
        .then(axios.spread((...reponse) => {
          this.isSaving = false
          this.goodsInputListInfo.push(param[0])
          this.$snotify.info('입고처리 되었습니다.')
          if (this.selectedOrder === null) {
            this.initInput(false)
          } else {
            this.selectedOrder.입고수량 = this.inputQty
            setTimeout(() => {
              this.onClickBefore()
            }, 400)
          }
        }))
        .catch(error => {
          this.isSaving = false
          this.initInput(false)
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    initInput (isSlide) {
      this.inputQty = null
      this.inputLotNo = null
      this.사급여부 = false
      this.입고업체 = null
      this.등록구분명 = '입고 등록'
      this.사급소재협력사코드 = null
      this.setValue(null, null, null, null, null)
      this.selectedPCode = null
      if (isSlide === false) {
        setTimeout(() => {
          this.$Q('#input-pcode').focus()
        }, 10)
      }
    },
    onClickBefore () {
      this.initInput(true)
      this.$Q('#left-draw-app-bar').css('position', 'static')
      this.$Q('.left-draw-content').css('padding-top', '0px')
      const vm = this
      this.$Q('.left-draw-wrap').animate({
        left: `${this.htmlWdth}px`
      }, 500, function () {
        vm.refreshDeliveryList()
      })
    },
    selectCompany (row) {
      this.입고업체 = row.거래처코드
      if (this.goodsType === ConstDef.자재유형_원자재) {
        this.등록구분명 = '일반 원자재 입고 등록'
        this.사급소재협력사코드 = null
        this.사급여부 = false
        if (row.사급여부 === 1) {
          this.등록구분명 = '사급 원자재 입고 등록'
          this.사급여부 = true
          this.$_sp.runNoEncodeFindProc('spFindAllVenderCodeByConsignedGoodsNo', { 원자재품번: this.selectedPCode })
            .then((data) => {
              const temp = this.$_sp.MakeModel(data)
              if (temp.length > 0) {
                this.사급소재협력사코드 = temp[0].거래처코드
              }
            })
        }
      }
    },
    onEnterPcode () {
      this.selectedPCode = this.selectedPCode.replace('?', ' ')
      const val = AppLib.base64DecodeSubstring(this.selectedPCode)
      const row = this.$_.findWhere(this.purchaseInfoList, { 품번: val })
      this.selectCompany(row)
      this.setValue(row.품번, row.단위, row.구매단위, row.변환배율, row.품명)
      this.$Q('#input-pcode').text(val)
    },
    onEnterQty (e) {
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) {
        this.$snotify.error('수량을 입력하여 주세요')
        return
      }
      if (this.selectedPCode === null || this.selectedPCode.length === 0) {
        this.$snotify.error('품목 정보를 선택하여 주세요')
        return
      }
      this.$Q('#input-lotNo').focus()
    },
    onEnterLotNo (e) {
      if (this.selectedPCode === null || this.selectedPCode.length === 0) {
        this.$snotify.error('품목 정보를 선택하여 주세요')
        return
      }
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) {
        this.$snotify.error('수량을 입력하여 주세요')
        return
      }
      this.$Q('#input-pcode').focus()
      this.addInventory()
    },
    setValue (goodsNo, unitBom, unitText, unitRate, goodsName) {
      this.selectedPCode = goodsNo
      this.selectedGoodsName = goodsName

      this.unitText = unitText
      if (unitText !== undefined && unitText !== null) {
        this.unitText = this.$_.findWhere(this.unitLookup, { 코드: unitText }).코드명
      }

      this.unitBom = unitBom
      if (unitBom !== undefined && unitText !== null) {
        this.unitBom = this.$_.findWhere(this.unitLookup, { 코드: unitBom }).코드명
      }
      this.unitRate = unitRate
    },
    onPCodeChange (goodsNo) {
      const row = this.$_.findWhere(this.purchaseInfoList, { 품번: goodsNo })
      this.setValue(row.품번, row.단위, row.구매단위, row.변환배율, row.품명)
      this.입고업체 = row.거래처코드
      this.selectCompany(row)
      setTimeout(() => {
        this.showQrScaner = 'none'
        this.$Q('#input-qty').focus()
        this.$Q('.v-autocomplete__content').css('display', 'none')
      }, 300)
    },
    onPCodeScan (result) {
      this.selectedPCode = result
      this.onEnterPcode(null)
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('창고수불', [{ 발주번호: row.data.발주번호 }])
        .then((data) => {
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshInputGoodsList () {
      const param = {
        입출고구분: ConstDef.자재입고,
        입고업체: null,
        userId: this.userInfo.userId
      }
      this.$_sp.runNoEncodeFindProc('spFindAllInputGoosListByDelNoAndToday', param)
        .then((data) => {
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
        })
    },
    refreshPurchaseInfo () {
      const array = []
      // array.push(this.$_sp.runNoEncodeFindProc('spFindAllPurchaseByType', { 자재유형: this.goodsType }))
      array.push(this.$_sp.runNoEncodeFindSqlProc('품목정보', null))
      axios.all(array)
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          this.purchaseInfoList = this.$_sp.MakeModel(reponse[0])
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .left-draw-content {
    background-color: black;
  }
</style>
